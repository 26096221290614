/* Styles for different accordion headers */
.accordion-header-1 {
  background-color: #4caf50; /* Green */
  color: white;
}

.accordion-header-2 {
  background-color: #2196f3; /* Blue */
  color: white;
}

.accordion-header-3 {
  background-color: #ff9800; /* Orange */
  color: white;
}

.accordion-header-4 {
  background-color: #9c27b0; /* Purple */
  color: white;
}

/* Style for increasing accordion content font size */
.accordion-content-header {
  font-size: 1.3rem; /* Adjust as needed */
}

/* Style for increasing accordion content font size */
.accordion-content {
  font-size: 1.2rem; /* Adjust as needed */
}

.banner {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: auto;
  display: block;
}

.btn-sponsor {
  background-color: #ff9800; /* Orange */
  color: white; /* Text color */
}

.btn-listen {
  background-color: #2196f3; /* Blue */
  color: white; /* Text color */
}

.btn-braille {
  background-color: #f44336; /* Red */
  color: white; /* Text color */
}

.btn-teens {
  background-color: #4caf50; /* Green */
  color: white; /* Text color */
}

.btn-login {
  background-color: #ff9800; /* Orange */
  color: white; /* Text color */
}

.btn-register {
  background-color: #2196f3; /* Blue */
  color: white; /* Text color */
}

/* Optional: Add hover effects */
.btn-sponsor:hover,
.btn-listen:hover,
.btn-braille:hover,
.btn-teens:hover,
.btn-login:hover,
.btn-register:hover {
  opacity: 0.8; /* Slightly transparent on hover */
}

card {
  border-width: 0;
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0, 34, 28, 0.1);
  border-radius: 0.3125rem;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.bg-primary-dark {
  --bs-bg-opacity: 1;
  background-color: #005c45 !important;
}
.position-relative {
  position: relative !important;
}
.overflow-hidden {
  overflow: hidden !important;
}

.content-space-2 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

justify-content-center {
  justify-content: center !important;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.justify-content-center {
  justify-content: center !important;
}
*,
::after,
::before {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
user agent stylesheet div {
  display: block;
}
.bg-primary-dark {
  --bs-bg-opacity: 1;
  background-color: #005c45 !important;
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
.container {
  flex: 1;
}

footer {
  background-color: var(--primary-dark);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto; /* This ensures the footer sticks to the bottom */
}
