

.custom-skeleton {
    border: 1px solid var(--surface-d);
    border-borderRadius: 4px;
}
.custom-skeleton ul {
    list-style: none;
}

.login-body .login-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
}

.imgLogin {
    vertical-align: middle;
    border-style: none;
    width: 100%;
    height: auto;
    max-width: 828px;
}

.user {
    align-items: center;
    background: #0000;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: 0 0.625rem;
    transition: background-color .12s ease-out;
}

.avatar-image{
    font-size: 0.4rem;
    margin-left: 0.5rem;
     margin-right: 0.5rem;
}


.user__info {
    display: flex;
    flex-direction: column;
    line-height: 1;
    margin-left: 0.5rem;
    padding-right: 0.25rem;
}

.user__title {
    font-size: .875rem;
    font-weight: 500;
}


.form-demo .card {
    min-width: 450px;
}
.form-demo .card form {
    margin-top: 2rem;
}
.form-demo .card .field {
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
    .form-demo .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 0;
    }
}




.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}